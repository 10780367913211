/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2a0d219b-6c56-4fc2-a80e-a6bbb444af22",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_EpDhcFnau",
    "aws_user_pools_web_client_id": "157at1dju5sf9942f0tmk8g9i8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://hzlblm6syzb2hex4kqmjzpx7le.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://saqknse4mf.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminListUsersApi",
            "endpoint": "https://5huauustm6.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminCreateUsersApi",
            "endpoint": "https://pvz3rmonki.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminCreateQuestionFromRakurekiApi",
            "endpoint": "https://wag9clqbq2.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminShohousenListApi",
            "endpoint": "https://7kbr9skdp9.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminShohousenSetFlgApi",
            "endpoint": "https://4q15ghs767.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminCallUserApi",
            "endpoint": "https://pecr2kbpz1.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminQuestionListApi",
            "endpoint": "https://ficvu5fuza.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminQuestionApi",
            "endpoint": "https://kjkh3gstb5.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminGetUserFromRakurekiIDApi",
            "endpoint": "https://lldz2tkv49.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminGetNotHaveMIDUserApi",
            "endpoint": "https://br90wcktb7.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "checkExistMimamoriUserApi",
            "endpoint": "https://xv87ka0lkl.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "windyadmin43c46065694a46a6807f9e44e587905b234545-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "cd7ead0f4e50403f818e3ebe6fcfba69",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
