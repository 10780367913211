import React from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../App'
import { LogoAlphaFull } from '../extra/svg/'

const Sidebar = () => (
	<>
	<UserContext.Consumer>
	{(contextData) => (
		<div className="sidebar-menu">
			<div className="sidebar-menu-inner">
				<header className="logo-env">
					<div className="logo">
						<a href="/">
							<LogoAlphaFull width={"151px"} height={"44px"} color={"#FFF"} />
						</a>
					</div>
					<div className="sidebar-collapse">
						<a href="#" className="sidebar-collapse-icon">
							<i className="entypo-menu"></i>
						</a>
					</div>
					<div className="sidebar-mobile-menu visible-xs">
						<a href="#" className="with-animation">
							<i className="entypo-menu"></i>
						</a>
					</div>
				</header>
				<div className="sidebar-user-info">
					{contextData.group=="Pharmacy" ?
					<div>
						<a href="#" className="user-link">
							<img src="/assets/images/thumb-1@2x.png" width="55" alt="" className="img-circle" />
							<span>{contextData.data.pharmacyGroup.name == "なし" ? "" : contextData.data.pharmacyGroup.name}</span>
							<strong>{contextData.data.name}</strong>
						</a>
					</div>
					: null
					}
				</div>
				<ul id="main-menu" className="main-menu">
					{/* <li className="opened active has-sub">
						<a href="index.html">
							<i className="entypo-gauge"></i>
							<span className="title">Dashboard</span>
						</a>
						<ul className="visible">
							<li>
								<a href="index.html">
									<span className="title">Dashboard 1</span>
								</a>
							</li>
							<li className="active">
								<a href="dashboard-2.html">
									<span className="title">Dashboard 2</span>
								</a>
							</li>
							<li>
								<a href="dashboard-3.html">
									<span className="title">Dashboard 3</span>
								</a>
							</li>
							<li className="has-sub">
								<a href="skin-black.html">
									<span className="title">Skins</span>
								</a>
								<ul>
									<li>
										<a href="skin-black.html">
											<span className="title">Black Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-white.html">
											<span className="title">White Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-purple.html">
											<span className="title">Purple Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-cafe.html">
											<span className="title">Cafe Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-red.html">
											<span className="title">Red Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-green.html">
											<span className="title">Green Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-yellow.html">
											<span className="title">Yellow Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-blue.html">
											<span className="title">Blue Skin</span>
										</a>
									</li>
									<li>
										<a href="skin-facebook.html">
											<span className="title">Facebook Skin</span>
											<span className="badge badge-secondary badge-roundless">New</span>
										</a>
									</li>
								</ul>
							</li>
							<li>
								<a href="highlights.html">
									<span className="title">What's New</span>
									<span className="badge badge-success badge-roundless">v2.0</span>
								</a>
							</li>
						</ul>
					</li> */}
					<li>
						<a href="/">
							<i className="entypo-monitor"></i>
							<span className="title">ダッシュボード</span>
						</a>
					</li>			
					<li className="has-sub">
						<a>
							<i className="entypo-user"></i>
							<span className="title">患者</span>
						</a>
						<ul>
							<li>
								<Link to="/Members">
									<span className="title">一覧・登録</span>
								</Link>
							</li>
						</ul>
					</li>
					<li className="has-sub">
						<a href="">
							<i className="entypo-heart"></i>
							<span className="title">患者フォロー</span>
						</a>
						<ul>
							<li>
								<Link to="/SendQuestionnaire">
									<span className="title">アンケート一覧</span>
								</Link>
							</li>
							{contextData.group=="Pharmacy" ?
							<>
							<li>
								<Link to={"/questionSelectSend/" + contextData.data.id}>
									<span className="title">アンケート一斉送信（らく歴連動）</span>
								</Link>
							</li>
							<li>
								<Link to={"/questionAllSend/" + contextData.data.id}>
									<span className="title">アンケート一斉送信（全メンバー）</span>
								</Link>
							</li>
							<li>
								<Link to={"/questionTemplate/" + contextData.data.id}>
									<span className="title">アンケートテンプレート設定</span>
								</Link>
							</li>
							</>
							: null}
						</ul>
					</li>
					{contextData.group=="Pharmacy" ? null : 
					<li className="has-sub">
						<a href="#">
							<i className="entypo-home"></i>
							<span className="title">薬局</span>
						</a>
						<ul>
							<li>
								<Link to="/pharmacyGroups">
									<span className="title">薬局グループ</span>
								</Link>
							</li>
							<li>
								<Link to="/pharmacies">
									<span className="title">薬局</span>
								</Link>
							</li>
						</ul>
					</li>
					}
					{contextData.group=="Pharmacy" ? null : 
					<li className="has-sub">
						<a href="#">
							<i className="entypo-home"></i>
							<span className="title">各種マスタ設定</span>
						</a>
						<ul>
							<li>
								<Link to="/questionGroups">
									<span className="title">アンケートテンプレートカテゴリ</span>
								</Link>
							</li>
						</ul>
					</li>
					}
					<li className="has-sub">
						<a href="#">
							<i className="entypo-home"></i>
							<span className="title">お知らせ</span>
						</a>
						<ul>
							<li>
								<Link to="/sendInformations">
									<span className="title">お知らせ送信</span>
								</Link>
							</li>
							{contextData.group=="Pharmacy" ?
							<li>
								<Link to="/informations">
									<span className="title">運営からのお知らせ</span>
								</Link>
							</li>
							: null}
						</ul>
					</li>
					{contextData.group=="Pharmacy" ?
					<li className="has-sub">
						<a href="#">
							<i className="entypo-home"></i>
							<span className="title">設定</span>
						</a>
						<ul>
							<li>
								<Link to={"/pharmacy/" + contextData.data.id}>
									<span className="title">薬局詳細</span>
								</Link>
							</li>
							{/* <li>
								<Link to={"/questionSendSchedule/" + contextData.data.id}>
									<span className="title">アンケート自動送信予約設定</span>
								</Link>
							</li>							 */}
							<li>
								<Link to={"/QR/" + contextData.data.id}>
									<span className="title">薬局登録用QRコード</span>
								</Link>
							</li>
							{/* <li>
								<Link to={"/PointQR/" + contextData.data.id}>
									<span className="title">ポイント用QRコード</span>
								</Link>
							</li> */}
						</ul>
					</li>
					: null
					}
				</ul>
			</div>
		</div>
	)}
	</UserContext.Consumer>
	</>
);

export default Sidebar;