import React from 'react';

const Icon = (props) => {
  return (
    <svg id="グループ_56" data-name="グループ 56" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 305.613 80.047">
      <path id="パス_62" data-name="パス 62" d="M224.6,2181.78v-35.929c0-4.962-1.734-7.533-7.831-7.533h-8.788v43.462h-4.962v-43.462H194.35c-5.978,0-7.891,2.511-7.891,7.533v35.929H181.2v-35.989c0-8.429,3.467-12.734,12.794-12.734h23.2c9.266,0,12.674,3.946,12.674,12.734v35.989Z" transform="translate(-181.198 -2133.057)" fill={props.color}/>
      <path id="パス_63" data-name="パス 63" d="M641.187,2140.47v-7.413h5.321v7.413Zm0,41.25v-36.049h5.321v36.049Z" transform="translate(-583.257 -2133.057)" fill={props.color}/>
      <path id="パス_64" data-name="パス 64" d="M792.992,2263.458v-24.571c0-4.364-2.391-7.114-6.576-7.114H777.03v31.685h-5.082v-31.685h-9.266c-4.065,0-6.576,2.75-6.576,7.114v24.571h-5.261V2238.17c0-7.293,4.663-11.6,11.239-11.6h24.93c6.7,0,11.359,4.364,11.359,11.718v25.169Z" transform="translate(-679.104 -2214.795)" fill={props.color}/>
      <path id="パス_65" data-name="パス 65" d="M1200.461,2264.347h-13.033c-8.788,0-12.2-3.766-12.2-10.283v-2.332c0-6.157,3.109-10.223,12.016-10.223h15.065a13.949,13.949,0,0,1,5.022.9v-3.587c0-3.527-1.494-6.4-7.473-6.4h-22.718v-4.9h23.794c8.071,0,11.717,4.125,11.717,11.359v15.185C1212.656,2260.581,1209.189,2264.347,1200.461,2264.347Zm6.875-13.87c0-3.348-1.973-4.663-5.8-4.663h-13.511c-5.739,0-7.473,1.793-7.473,5.859v2.033c0,3.766,1.495,5.8,7.473,5.8H1199.8c5.679,0,7.533-1.614,7.533-5.8Z" transform="translate(-1050.045 -2215.624)" fill={props.color}/>
      <path id="パス_66" data-name="パス 66" d="M1560.586,2263.458v-24.571c0-4.364-2.391-7.114-6.576-7.114h-9.386v31.685h-5.082v-31.685h-9.266c-4.065,0-6.576,2.75-6.576,7.114v24.571h-5.261V2238.17c0-7.293,4.663-11.6,11.239-11.6h24.93c6.7,0,11.359,4.364,11.359,11.718v25.169Z" transform="translate(-1350.029 -2214.795)" fill={props.color}/>
      <path id="パス_67" data-name="パス 67" d="M1964.828,2263.458h-7.473c-8.728,0-14.527-5.2-14.527-13.391v-10.044c0-7.712,5.56-13.451,14.527-13.451h7.473c8.669,0,15.065,4.843,15.065,13.87v9.207C1979.894,2258.676,1973.5,2263.458,1964.828,2263.458Zm9.8-22.419c0-6.218-4.185-9.266-9.565-9.266h-7.712c-6.038,0-9.266,4.125-9.266,8.668v9.147c0,5.141,3.288,8.729,9.266,8.729h7.712c5.56,0,9.565-3.169,9.565-9.266Z" transform="translate(-1720.971 -2214.795)" fill={props.color}/>
      <path id="パス_68" data-name="パス 68" d="M2300.992,2232.248c-7.592,0-11.06,3.766-11.06,10.821v20.865h-5.321v-20.745c0-10.94,6.218-16.142,15.663-16.142h12.973v5.2Z" transform="translate(-2019.71 -2215.21)" fill={props.color}/>
      <path id="パス_69" data-name="パス 69" d="M2565.633,2140.47v-7.413h5.321v7.413Zm0,41.25v-36.049h5.321v36.049Z" transform="translate(-2265.341 -2133.057)" fill={props.color}/>
      <path id="パス_70" data-name="パス 70" d="M825.691,2631.655H820.83v-2.217h4.886c8.564,0,11.838-3.274,11.838-11.51V2614.3H820v-2.241h19.8v5.818C839.8,2627.2,835.842,2631.655,825.691,2631.655Z" transform="translate(-739.549 -2551.734)" fill={props.color}/>
      <path id="パス_71" data-name="パス 71" d="M1044.181,2650.656h-3.2v.73c0,4.307-2.368,5.743-6.574,5.743H1033.2v-2.091h1.133c3.325,0,4.609-1.033,4.609-4.03v-2.443h4.987c2.771,0,3.879-1.083,3.879-3.778V2643.2H1033.2v-2.141h16.775v3.829C1049.974,2648.867,1047.883,2650.656,1044.181,2650.656Z" transform="translate(-925.899 -2577.082)" fill={props.color}/>
      <path id="パス_72" data-name="パス 72" d="M1221.8,2676.876v-2.418h19.621v2.418Z" transform="translate(-1090.747 -2606.275)" fill={props.color}/>
      <path id="パス_73" data-name="パス 73" d="M1427.745,2623.294h-3.829l1.108,5.164h-2.317l-1.562-7.43h6.524c3.9,0,5.818-2.066,5.818-5.894V2610.7H1414.8v-2.242h20.981v6.675C1435.78,2620.473,1432.682,2623.294,1427.745,2623.294Z" transform="translate(-1259.441 -2548.588)" fill={props.color}/>
      <path id="パス_74" data-name="パス 74" d="M1636.9,2611.649c-.025,1.134-.025.856-.05,1.511-.4,8.69-5.415,13.375-14.231,13.375H1616.4v-2.217h6.322c7.833,0,11.636-4.357,11.888-11.083.025-.781.025-.453.05-1.788.076-2.972,1.31-4.912,3.627-5.189v2.317C1637.279,2608.753,1636.952,2610.138,1636.9,2611.649Zm-19.923-3.048v-2.065h10.075v2.065Zm0,7.254v-2.065h10.075v2.065Z" transform="translate(-1435.652 -2546.665)" fill={props.color}/>
      <path id="パス_75" data-name="パス 75" d="M1821.8,2676.876v-2.418h19.621v2.418Z" transform="translate(-1615.184 -2606.275)" fill={props.color}/>
    </svg>
  );
};

export default Icon;