
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Amplify
import Amplify, { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import config from '../aws-exports'

// GraphQL
import { searchMessages } from '../graphql/queries'

class Chat extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			messages: [],
			chatRooms: null
		};
	}

	// Init
	async componentDidMount() {
		console.log(this.props.messages)
		if (this.props.messages) {
			console.log("VVVVVVVVVVV")
			console.log(this.props.messages)

		}
	}

	render() {
		return (
			<></>
		);
	  }

}

export default Chat;