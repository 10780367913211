import React, { Component, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import routes from './config/routes'
import './assets/css/custom.scss'

// AWS Amplify
import Amplify, { Auth, API, graphqlOperation, Analytics } from "aws-amplify";
import { Authenticator, SignIn } from 'aws-amplify-react'
import config from './aws-exports'

// GraphQL
// import { getPharmacyByLoginId } from './graphql/queries'

// Common
import Login from './pages/Login'
import Layout from './common/Layout'
import { queryAdminInformationList, getPharmacy } from './graphql/queries';

export const UserContext = React.createContext({});

Amplify.configure(config)

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      authState: props.authState || null,
      authData: props.authData || null,
      pharmacy: {},
      contextData: {},
    }
  }

  change = async (state, data) => {
    if (state === 'signedIn') {
      const getParams = {
        id: data.attributes.sub
      }
  
      try {
        const user =  await Auth.currentAuthenticatedUser();
        const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"][0]

        let contextData = {
          group : userGroup
        }

        if (userGroup == "Admin") {
          contextData["data"] = {}
        } else if (userGroup == "Pharmacy") {
          const pharmacy = await API.graphql(graphqlOperation(getPharmacy, getParams))
          this.setState({
              pharmacy: pharmacy,
          })
          contextData["data"] = pharmacy.data.getPharmacy

          const getInput = {
            isFromAdmin: 1
          }
          const result = await API.graphql(graphqlOperation(queryAdminInformationList, getInput))
          const items = result.data.queryAdminInformationList.items
          const sortedItems = items.sort((a, b)=> {
            if (a.createdAt < b.createdAt) {
              return 1;
            } else {
              return -1;
            }
          })
          contextData["informations"] = sortedItems
        }

        this.setState({
          contextData: contextData,
        })
        
        console.log('state: ', this.state)
        console.log('user: ', user)
      } catch (e) {
          console.log(e)
      }
    } else if (state === "verifyContact") {
      window.location.reload()
    }
    this.setState({
      authState: state,
      authData: data,
    })  
    console.log("APP State:", this.state)

  }
windyadminba21d1cf
  render() {
    if (this.state.authState === 'signedIn') {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Switch>
              <UserContext.Provider value={this.state.contextData}>
                <Layout>
                    {
                      routes.map((route, index) => (
                          <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={props => (<route.component {...props} />)}
                          />
                      ))
                    }
                  </Layout>
                </UserContext.Provider>
            </Switch>
          </BrowserRouter>
        </Suspense>
      );
    } else {
      return (
        <Authenticator hideDefault={true} onStateChange={this.change}>
          <Login {...this.state} />
        </Authenticator>
      )  
    }
  }
}

export default App;
