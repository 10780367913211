import React from "react";
import { SignIn } from "aws-amplify-react";
import { LogoAlphaImgHorizontal } from "../extra/svg";

// Common
import Helmet from "react-helmet";

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <div className="login-container">
        <div className="login-header login-caret">
          <div className="login-content">
            <a href="/" className="logo">
              <LogoAlphaImgHorizontal
                width={"244px"}
                height={"191px"}
                color={"#FFF"}
              />
            </a>

            <p className="description">管理画面</p>

            <div className="login-progressbar-indicator">
              <h3>0%</h3>
              <span>logging in...</span>
            </div>
          </div>
        </div>

        <div className="login-progressbar">
          <div></div>
        </div>

        <div className="login-form">
          <div className="login-content">
            <div className="form-login-error">
              <h3>Invalid login</h3>
              <p>
                Enter <strong>demo</strong>/<strong>demo</strong> as login and
                password.
              </p>
            </div>

            <form id="form_login">
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-addon">
                    <i className="entypo-user"></i>
                  </div>

                  <input
                    onChange={this.handleInputChange}
                    type="text"
                    data-test="username-input"
                    className="form-control"
                    name="username"
                    key="username"
                    id="username"
                    placeholder="Username"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-addon">
                    <i className="entypo-key"></i>
                  </div>

                  <input
                    onChange={this.handleInputChange}
                    type="password"
                    data-test="sign-in-password-input"
                    className="form-control"
                    name="password"
                    key="password"
                    id="password"
                    placeholder="Password"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-group">
                <button
                  type="button"
                  onClick={() => super.signIn()}
                  className="btn btn-primary btn-block btn-login"
                >
                  <i className="entypo-login"></i>
                  ログイン
                </button>
              </div>
            </form>

            <div className="login-bottom-links">
              <a href="extra-forgot-password.html" className="link">
                パスワードを忘れた？
              </a>
              <br />
              <a href="itms-services://?action=download-manifest&url=https://dev.d1orzckkbvuoea.amplifyapp.com/app/manifest.plist">
                開発用アプリ(iOS)をインストールする
              </a>
              <br />
              <br />
              <a href="/android/app-debug.apk">
                開発用アプリ(Android)をインストールする
              </a>
              <br />
              <a href="#">Windy</a> - <a href="#">プライバシーポリシー</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomSignIn;
