import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";

// Amplify
import config from '../aws-exports'

// GraphQL
import { queryAdminInformationList, queryPharmacyInformationList } from '../graphql/queries'
import { createInformations, deleteInformations } from '../graphql/mutations'
import { UserContext } from '../App'
import Loading from '../extra/svg/Loading'
import InformationDetail from '../common/InformationDetail'

// Common
import useScript from '../hooks/useScript'
import Modal from "react-modal"

Amplify.configure(config)
Modal.setAppElement("#root")

const Header = props => {
    useScript('/assets/js/neon-chat.js');
    useScript('/assets/js/jquery-ui/js/jquery-ui-1.10.3.custom.min.js');
    useScript('/assets/js/neon-custom.js');

		const userContext = useContext(UserContext)
		const [informations, setInformations] = useState([])
		const [isOpenDetail, setIsOpenDetail] = useState(false)
		const [informationDetail, setIsInformationDetail] = useState(null)
	
		const openDetail = (data) => {
			if (data) {
				const newData = informations.filter((information)=>{
					return information.id != data.id
				})
				setInformations(newData)
				setIsInformationDetail(data)
			} else {
				setIsInformationDetail(null)
				setIsOpenDetail(false)
			}
		}

		useEffect(() => {
			if (informationDetail) {
				console.log(informationDetail)
				setIsOpenDetail(true)
			}
		}, [informationDetail]);

		useEffect(()=> {
			console.log("aaaaaaaaaaaaa", userContext)
			if (userContext && userContext.data) {
				if (userContext.data.readInformationsIds) {
					const filterInformations = userContext.informations.filter((information)=> {
						return !userContext.data.readInformationsIds.includes(information.id)
					})
					setInformations(filterInformations)					
				} else {
					setInformations(userContext.informations)					
				}
			}
		}, [userContext])

		const dateFormat = (date) => {
			if (date=="") return "不明"
			var _date = new Date(date)
			var y = _date.getFullYear()
			var m = _date.getMonth() + 1
			var d = ("0"+_date.getDate()).slice(-2)
			var h = ("0"+_date.getHours()).slice(-2)
			var mm = ("0"+_date.getMinutes()).slice(-2)
			return `${y}年${m}月${d}日 ${h}時${mm}分`
		}		
    
    return (
			<>
			<Modal
				isOpen={isOpenDetail}
				onRequestClose={() => openDetail(null)}
				overlayClassName={{
						base: "overlay-base",
						afterOpen: "overlay-after",
						beforeClose: "overlay-before"
				}}
				className={{
						base: "content-base",
						afterOpen: "content-information-after",
						beforeClose: "content-before"
				}}
				closeTimeoutMS={500}
			>
					<InformationDetail informationDetail={informationDetail} />
			</Modal>   			
			<div className="row">
				<div className="col-md-6 col-sm-8 clearfix">   
					{userContext.group == "Pharmacy" ? 
					<ul className="user-info pull-left pull-none-xsm">
						<ul className="user-info pull-left pull-right-xs pull-none-xsm">
							<li className="notifications dropdown">
								<a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
									<i className="entypo-bell"></i>
									{informations.length > 0 ?
									<span className="badge badge-danger">{informations.length}</span>
									:
									null
									}
								</a>
								<ul className="dropdown-menu">
									<li className="top">
										{informations.length > 0 ?
										<p className="small">
											新しいお知らせが <strong>{informations.length}</strong> 件あります
										</p>
										:
										<p className="small">
											新しいお知らせはありません
										</p>
										}
									</li>
								{informations.map((information, index)=> {
										return (
											<li style={{cursor: "pointer"}} key={index}>
												<ul className="dropdown-menu-list scroller" tabIndex="5001">
													<li>
														<a onClick={() => openDetail(information)}>
															<span className="line">
																<strong>{information.title}</strong>
															</span>
															<span className="line small">
																{dateFormat(information.createdAt)}
															</span>
														</a>
													</li>
												</ul>
											</li>	
										)
									})}
								</ul>
							</li>
						</ul>
					</ul>
					: null}
				</div>    
				<div className="col-md-6 col-sm-4 clearfix">
					<ul className="list-inline links-list pull-right">      
						<li>
							<Link to="/" onClick={() => Auth.signOut()}>
									ログアウト <i className="entypo-logout right"></i>
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<hr />
			</>
    )
};

export default Header
