import React from 'react';

const Icon = (props) => {
  return (
    <svg id="グループ_60" data-name="グループ 60" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 244.642 191.927">
      <g id="グループ_58" data-name="グループ 58" transform="translate(35.182 0)">
        <g id="グループ_57" data-name="グループ 57">
          <path id="パス_76" data-name="パス 76" d="M860.132,1795.2v-38.887c0-17.883-6.248-27.147-28.224-27.147H800.236v53.557H782.353v-53.557H751.113c-21.545,0-28.44,9.049-28.44,27.147V1795.2h-18.96v-39.1c0-30.379,12.5-45.891,46.107-45.891h83.6c33.4,0,45.676,14.22,45.676,45.891v39.1Z" transform="translate(-703.713 -1710.209)" fill={props.color}/>
          <path id="パス_77" data-name="パス 77" d="M1119.945,2370.015s15.042,13.427,35.27,13.427,35.27-13.427,35.27-13.427" transform="translate(-1067.525 -2286.92)" fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width="10"/>
        </g>
        <circle id="楕円形_2" data-name="楕円形 2" cx="9.279" cy="9.279" r="9.279" transform="translate(42.744 41.729)" fill={props.color}/>
        <circle id="楕円形_3" data-name="楕円形 3" cx="9.279" cy="9.279" r="9.279" transform="translate(114.076 41.729)" fill={props.color}/>
      </g>
      <g id="グループ_59" data-name="グループ 59" transform="translate(0 123.197)">
        <path id="パス_78" data-name="パス 78" d="M459.1,2727.446v-28.762c0-3.972-1.388-6.03-6.269-6.03H445.8v34.792h-3.972v-34.792h-6.939c-4.786,0-6.317,2.01-6.317,6.03v28.762h-4.212v-28.81c0-6.748,2.776-10.194,10.242-10.194h18.568c7.418,0,10.146,3.158,10.146,10.194v28.81Z" transform="translate(-424.355 -2688.442)" fill={props.color}/>
        <path id="パス_79" data-name="パス 79" d="M792.576,2694.376v-5.934h4.259v5.934Zm0,33.021V2698.54h4.259V2727.4Z" transform="translate(-746.203 -2688.442)" fill={props.color}/>
        <path id="パス_80" data-name="パス 80" d="M914.094,2792.831v-19.669c0-3.494-1.914-5.7-5.264-5.7h-7.514v25.365h-4.068v-25.365h-7.418c-3.254,0-5.264,2.2-5.264,5.7v19.669h-4.211v-20.244c0-5.838,3.733-9.284,9-9.284h19.956c5.36,0,9.093,3.494,9.093,9.38v20.148Z" transform="translate(-822.927 -2753.875)" fill={props.color}/>
        <path id="パス_81" data-name="パス 81" d="M1240.271,2793.543h-10.433c-7.035,0-9.763-3.015-9.763-8.232v-1.866c0-4.929,2.489-8.184,9.619-8.184h12.06a11.173,11.173,0,0,1,4.02.718v-2.871c0-2.824-1.2-5.121-5.982-5.121h-18.186v-3.924h19.047c6.461,0,9.38,3.3,9.38,9.093v12.156C1250.034,2790.528,1247.258,2793.543,1240.271,2793.543Zm5.5-11.1c0-2.68-1.579-3.733-4.642-3.733h-10.816c-4.594,0-5.982,1.436-5.982,4.69v1.627c0,3.015,1.2,4.642,5.982,4.642h9.428c4.546,0,6.03-1.292,6.03-4.642Z" transform="translate(-1119.863 -2754.539)" fill={props.color}/>
        <path id="パス_82" data-name="パス 82" d="M1528.55,2792.831v-19.669c0-3.494-1.914-5.7-5.264-5.7h-7.513v25.365H1511.7v-25.365h-7.418c-3.254,0-5.264,2.2-5.264,5.7v19.669h-4.211v-20.244c0-5.838,3.733-9.284,9-9.284h19.957c5.36,0,9.093,3.494,9.093,9.38v20.148Z" transform="translate(-1359.999 -2753.875)" fill={props.color}/>
        <path id="パス_83" data-name="パス 83" d="M1852.142,2792.831h-5.982c-6.987,0-11.629-4.164-11.629-10.72v-8.04c0-6.174,4.451-10.768,11.629-10.768h5.982c6.939,0,12.06,3.876,12.06,11.1v7.37C1864.2,2789,1859.082,2792.831,1852.142,2792.831Zm7.849-17.947c0-4.977-3.35-7.418-7.657-7.418h-6.173c-4.834,0-7.418,3.3-7.418,6.939v7.322c0,4.116,2.632,6.987,7.418,6.987h6.173c4.451,0,7.657-2.536,7.657-7.418Z" transform="translate(-1656.936 -2753.875)" fill={props.color}/>
        <path id="パス_84" data-name="パス 84" d="M2121.242,2767.847c-6.078,0-8.854,3.015-8.854,8.662v16.7h-4.259V2776.6c0-8.758,4.977-12.921,12.539-12.921h10.385v4.164Z" transform="translate(-1896.077 -2754.207)" fill={props.color}/>
        <path id="パス_85" data-name="パス 85" d="M2333.084,2694.376v-5.934h4.259v5.934Zm0,33.021V2698.54h4.259V2727.4Z" transform="translate(-2092.702 -2688.442)" fill={props.color}/>
        <path id="パス_86" data-name="パス 86" d="M825.691,3097.182H820.83v-2.217h4.886c8.564,0,11.838-3.274,11.838-11.51v-3.627H820v-2.242h19.8v5.818C839.8,3092.724,835.842,3097.182,825.691,3097.182Z" transform="translate(-770.172 -3028.578)" fill={props.color}/>
        <path id="パス_87" data-name="パス 87" d="M1044.181,3116.182h-3.2v.73c0,4.307-2.368,5.743-6.574,5.743H1033.2v-2.091h1.133c3.325,0,4.609-1.032,4.609-4.03v-2.443h4.987c2.771,0,3.879-1.083,3.879-3.778v-1.587H1033.2v-2.141h16.775v3.828C1049.974,3114.394,1047.883,3116.182,1044.181,3116.182Z" transform="translate(-956.522 -3053.926)" fill={props.color}/>
        <path id="パス_88" data-name="パス 88" d="M1221.8,3142.4v-2.418h19.621v2.418Z" transform="translate(-1121.37 -3083.119)" fill={props.color}/>
        <path id="パス_89" data-name="パス 89" d="M1427.745,3088.822h-3.829l1.108,5.163h-2.317l-1.562-7.43h6.524c3.9,0,5.818-2.065,5.818-5.894v-4.433H1414.8v-2.242h20.981v6.675C1435.78,3086,1432.682,3088.822,1427.745,3088.822Z" transform="translate(-1290.064 -3025.431)" fill={props.color}/>
        <path id="パス_90" data-name="パス 90" d="M1636.9,3077.176c-.025,1.133-.025.856-.05,1.511-.4,8.689-5.415,13.374-14.231,13.374H1616.4v-2.216h6.322c7.833,0,11.636-4.357,11.888-11.083.025-.781.025-.453.05-1.788.076-2.972,1.31-4.912,3.627-5.189v2.317C1637.279,3074.28,1636.952,3075.665,1636.9,3077.176Zm-19.923-3.048v-2.065h10.075v2.065Zm0,7.254v-2.065h10.075v2.065Z" transform="translate(-1466.275 -3023.508)" fill={props.color}/>
        <path id="パス_91" data-name="パス 91" d="M1821.8,3142.4v-2.418h19.621v2.418Z" transform="translate(-1645.807 -3083.119)" fill={props.color}/>
      </g>
    </svg>


  );
};

export default Icon;